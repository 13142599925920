import { mapGetters } from "vuex";
import pageheader from "../../common/pageheader";
import utils from "../../../utils/utils";
// import moment from "moment";
import metadatatoolsService from "../../../api/metadatatools.service";
import refresh from "../../secured/SupportToolsDialogs/RefreshButton";
import XLSX from "xlsx";
import UploadDialog from "../../secured/SupportToolsDialogs/UploadDialog";
import LogsDialog from "../../secured/SupportToolsDialogs/LogsDialog";
import ExecuteDialog from "../../secured/SupportToolsDialogs/ExecuteDialog";
export default {
  name: 'metadata-update-tool',
  components: {XLSX,pageheader, refresh, UploadDialog,LogsDialog,ExecuteDialog},
  props: [],
  data () {
    return {
      input: {},
      page: 0,
      files: [],
      MetadataToolsList:[],
      showUpload: false,
      isLoading: false,
      MetadataListSearch: "",
      apiToken: "",
      customerId: "",
      headers: [
        { text: "S.No", value: "sno", sortable: false },
        { text: "File Name", value: "fileName" },
        { text: "Date Time", value: "startDate" },
        { text: "Status", value: "status" },
        {
          text: "Actions",
          value: "view",
          align: "center",
          width: "240px",
          sortable: false,
        },
      ],
      Logsdialog: false,
      viewLogs: [],
      LogsSearch: "",
      loadingLogs: false,
      fileName: "",
      Executedialog: false,
      viewLogheaders: [
        {text: 'S.No', value: 'sno', align: "center", sortable: false,},
        {text: 'Serial Number', value: 'serialNumber'},
        {text: 'Name', value: 'name'},
        {text: 'Type', value: 'type'},
        {text: 'Brand', value: 'brand'},
        {text: 'Model', value: 'model'},
        {text: 'Year', value: 'year'},
        {text: 'Vin', value: 'vin'},
        {text: 'PO / External Reference', value: 'externalReference'},
        {text: 'Is Persisted', value: 'isPersisted'},
        {text: 'Error Message', value: 'errorMessage'}
      ],
      MetadataToolsListExcel: [],
      MetadataExcel: [],
      SelectedTool: "",
      selectedId: "",
      fileLoader: false,
      Id: "",
      companyToken: "",
      dialog: "",
    }
  },
  computed: {
    ...mapGetters(["roles", "userId","customerToken","customerReferenceId","companyName","reportId"])
  },
  mounted () {
    this.input = utils.getInputContent("metadataUpdateTool", this.roles);
    this.Id = this.companyName;
    this.companyToken = this.$store.getters.customerToken;
    this.customerId = this.$store.getters.customerReferenceId;
    this.GetMeatadataToolsList();
  },
  methods: {
     //Upload Dialog Code
    openUploadDialog() {
      this.files = [];
      this.$refs.uploadModal.showModal();
    },
    closeUploadDialog() {
      this.$refs.uploadModal.closeUploadDialog();
    },
     //get files from child component
    getList(files){
      this.files = [];
      this.files = files;
    },
      //Upload File
      async UploadFile() {
        this.fileLoader = true;
        let payload = {
          userId: this.userId,
          fileInfo: this.files[0],
        };
        let headerpayload = {
          customerId: this.reportId,
          // apiToken: this.companyToken,
        };
        if (this.files[0] == null) {
          this.$toast.error("Please select a file ");
        } else if (!this.files[0].fileName.includes(".xlsx")) {
          this.$refs.dropzone.removeAllFiles();
          this.$toast.error("please select only files with extension .xlsx");
        } else {
          try {
            this.selecteditem = this.selectedTitle;
            this.$toast.success("File is being processed. Please wait.");
            this.closeUploadDialog();
            let apiResult = await metadatatoolsService.UploadMetadataTools(
              payload,
              headerpayload
            );
            
            if (apiResult.isSuccess == true) {
              this.closeUploadDialog();
              this.fileLoader = false;
              this.$toast.success(apiResult.systemMessage);
              this.GetMeatadataToolsList();
            }
          } catch (err) {
            this.$toast.error("Unable to upload file" + err);
            this.fileLoader = false;
            this.closeUploadDialog();
          }
        }
      },
    //Get Tools List
    async GetMeatadataToolsList() {
      this.isLoading = true;
      let payload = {
        customerId: this.reportId,
        // apiToken: this.companyToken,
      };
      try {
        let response = await metadatatoolsService.GetMetadataTools(payload);
        if (response) {
          this.isLoading = false;
          this.MetadataToolsList = [];
          this.MetadataToolsList = response.map((d, index) => ({
            ...d,
            sno: index + 1,
            index: false,
          }));
        }
      } catch (err) {
        this.$toast.error("Unable to load the List" + err);
        this.isLoading = false;
      }
    },
    getColor(status) {
      if (status == "Completed") return "green";
      else if (status == "LogsCreated") return "blue";
      else if (status == "ExecutionInProgress") return "#013220";
      else if (status == "InProgress") return "orange";
      else if (status == "Failed") return "red";
      else return "black";
    },
    //Get View Logs
    async openMeatadataLogsDialog(item) {
      this.$refs.LogModal.showModal();
      this.viewLogs = [];
      this.loadingLogs = true;
      this.Logsdialog = true;
      this.fileName = item.fileName;
      let toolId = item.toolId;
      let headerpayload = {
        customerId: this.reportId,
        // apiToken: this.companyToken,
        toolId: toolId
      };
      try {
        let response = await metadatatoolsService.GetMetadataById(toolId, headerpayload);
        if (response) {
          this.loadingLogs = false;
          this.MetadataToolsListExcel = response;
          // this.ToolsListExcel = this.ToolsListExcel.map(function(item) {
          //   delete item.id;
          //   return item;
          // });
          this.viewLogs = response.map((d, index) => ({
            ...d,
            sno: index + 1,
          }));
          this.excelfilename = this.excelfilename + "_Output";
          this.sheetName = this.excelfilename.replace(".xlsx", "");
        }
      } catch (err) {
        this.$toast.error("Unable to Execute" + err);
        this.loadingLogs = false;
      }
    },
    //Execute Logs
    openExecuteDialog(toolId,index) {
      this.SelectedTool = toolId;
      this.selectedId = index;
      this.$refs.ExecuteModal.showModal();
    },
    closeExecuteDialog() {
      this.$refs.ExecuteModal.closeExecuteDialog();
    },
      async ShowExecutedLogs(id,index){
        var t = this;
        console.log(index);
        let payload = {
          userId: this.userId,
          customerId: this.reportId,
          toolId: id,
        }
        let headerpayload = {
          customerId: this.reportId,
          // apiToken: this.companyToken,
        }
        try {
          let response = await metadatatoolsService.ExecuteMetadataTools(payload,headerpayload);
          if (response.isSuccess == true) {
          this.$toast.success("Execution in progress!!");
          t.MetadataToolsList[index-1]["index"] = true;
          this.closeExecuteDialog();
          }
          else{
            t.MetadataToolsList[index-1]["index"] = false;
            this.closeExecuteDialog();
            this.$toast.error("Unable to Execute Please try again after some time");
          }
        } catch (err) {
          this.$toast.error("Unable to Execute" + err);
          this.closeExecuteDialog();
          this.loading = false;
        }
        
      },
      OnMetadataExport () { 
        this.MetadataExcel = [];
        this.MetadataExcel.push([
          "Serial Number",
          "Name",
          "Type",
          "Brand",
          "Model",
          "Year",
          "Vin",
          "PO / External Reference",
          "Is Persisted",
          "Error Message"
        ]);
        var ws = XLSX.utils.aoa_to_sheet(this.MetadataExcel);
        let Metadataarray = this.MetadataToolsListExcel.map(function(item) { 
          delete item.id;
          delete item.machineId; 
          return item; 
      });
        XLSX.utils.sheet_add_json(ws, Metadataarray, {
          header: [
            "serialNumber",
            "name",
            "type",
            "brand",
            "model",
            "year",
            "vin",
            "externalReference",
            "isPersisted",
            "errorMessage"
          ],
          skipHeader: true,
          origin: "A2",
        });
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Metadata Update Logs");
         this.MetadataExcel = [];
         XLSX.writeFile(wb, "Metadata Update Logs.xlsx");
      }
  }
}


