
export default {
  name: 'refresh-button',
  components: {},
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    RefreshTools(){
      this.$emit("RefreshTools");
    }
  }
}


