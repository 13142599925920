
export default {
  name: 'logs-dialog',
  components: {},
  props: ["viewLogheaders","viewLogs","fileName","loadingLogs"],
  data () {
    return {
      Logsdialog:false,
      page:0,
      LogsSearch:"",
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    showModal(){
      this.Logsdialog = true;
    },
    closeLogsDialog(){
      this.Logsdialog = false;
    },
    LoadExcel(){
      this.$emit("LoadExcel");
    }
  }
}


