
export default {
  name: 'execute-dialog',
  components: {},
  props: [],
  data () {
    return {
      Executedialog:false,
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    showModal(){
      this.Executedialog = true;
    },
    closeExecuteDialog(){
      this.Executedialog = false;
    },
    showExecute(){
    this.$emit("showExecute");
    },
  }
}


