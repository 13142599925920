import utils from "../../../../utils/utils";
import Dropzone from "../../../../views/DropZone.vue";
import EventBus from "../../../../utils/EventBus";
export default {
  name: 'upload-dialog',
  components: {Dropzone},
  props: ["filesList","fileLoader","selectedCompanyName", "companiesList", "fromActivation"],
  data () {
    return {
      dialog:false,
      showUpload:false,
      errorMessage:"",
      selectedCompany: ""
    }
  },
  computed: {

  },
  mounted () {
    EventBus.$on('upload-error-message', (msg) => {
      this.errorMessage = msg;
    });
  },
  beforeDestroy() {
    EventBus.$off("upload-error-message");
   },
  methods: {
    showModal(){
      this.dialog = true;
    },
    closeUploadDialog(){
      this.dialog = false;
      this.errorMessage = "";
      this.selectedCompany = "";
      this.$refs.dropzone.removeAllFiles();
    },
    dropzoneS(file) {
      utils.fileToBase64(file).then((base64String) => {
        let fileData = {
          fileName: file.name,
          fileContent: base64String.split(",")[1],
        };
        this.filesList.push(fileData);
        this.showUpload = true;
      }
      );
    },
    dropzoneR(file) {
      utils.fileToBase64(file).then((base64String) => {
        let filterFiles = this.filesList.filter(function(a) {
          return a.fileName != file.name && a.fileContent != base64String;
        });
        this.filesList = filterFiles;
      });
    },
    UploadFile(){
      this.errorMessage = "";
      this.$emit('getList', this.filesList);
      if (this.fromActivation) {
        this.$emit("UploadFile", this.selectedCompany);
      } else {
        this.$emit("UploadFile");
      }
    },
  }
}


